//import useState hook to create menu collapse state
import { useEffect, useState } from "react"

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from "react-pro-sidebar"

//import icons from react icons
import { FaList } from "react-icons/fa"
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle
} from "react-icons/fi"
import { BiCog } from "react-icons/bi"
import { IoAppsSharp, IoBagAddOutline, IoSettingsOutline } from "react-icons/io5"

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css"
import "./Dashboard.css"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import PracticeList from "./practices/PracticeList"
import PracticeDetails from "./practices/PracticeDetails"
import axios from "axios"
import { getGraphApiAuthHeader, MS_GRAPH_API_BASE_URI } from "authHelper"
import { Toast } from "react-bootstrap"
import OnBoardPracticeList from "./onboard/OnBoardPracticeList"
import OnboardPracticeDetails from "./onboard/OnboardPracticeDetails"
import OnboardNewPractice from "./onboard/OnboardNewPratice"
import PHNSettings from "./phn/PHNSettings"
import PbiReport from "./telemetry/PbiReport"

const Dashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { instance, accounts } = useMsal()

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    })
  }

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const { REACT_APP_APP_VERSION, REACT_APP_PHNNAME, REACT_APP_ENABLE_TELEMETRY } = process.env

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }

  async function fetchData() {
    const headerConfig = await getGraphApiAuthHeader(instance, accounts, ["User.Read"])
    const res = await axios.get(`${MS_GRAPH_API_BASE_URI}/me`, headerConfig)
    setUserProfile(res.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div id="dashboard">
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader>
          <div className="logotext" onClick={menuIconClick}>
            {/* small and big change using menucollapse state */}
            <p style={{ "height": "50px" }}>
              {menuCollapse ? "PS" : <img height="50px" src={require("../../logo.png")} alt="..." />}
              <span className="closemenu" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
                {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
              </span>
            </p>
            <p className="lh-sm fw-normal">
              {userProfile.displayName}
            </p>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => { navigate("home") }}
              active={location.pathname.endsWith("home") || location.pathname === "/dashboard"}
              icon={<FiHome />}>
              Home
            </MenuItem>
            <MenuItem
              onClick={() => { navigate("onboard") }}
              active={location.pathname.endsWith("onboard")}
              icon={<IoBagAddOutline />}>
              Onboard Practice
            </MenuItem>
            <MenuItem
              onClick={() => { navigate("practices") }}
              active={location.pathname.endsWith("practices")}
              icon={<IoAppsSharp />}>
              Practice Monitoring
            </MenuItem>
            <MenuItem
              hidden={REACT_APP_ENABLE_TELEMETRY == 'false'}
              onClick={() => { navigate("telemetry") }}
              active={location.pathname.endsWith("telemetry")}
              icon={<IoAppsSharp />}>
              Telemetry
            </MenuItem>
            <MenuItem
              onClick={() => { navigate("phn") }}
              active={location.pathname.endsWith("phn")}
              icon={<IoSettingsOutline />}>
              PHN Settings
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem icon={<FiLogOut />} onClick={handleLogout}>Logout</MenuItem>
            <MenuItem>Version {REACT_APP_APP_VERSION}</MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>

      <main>
        <Routes>
          <Route path="" element={<Home userProfile={userProfile} />}></Route>
          <Route path="home" element={<Home userProfile={userProfile} />}></Route>
          <Route path="practices" element={<PracticeList />}></Route>
          <Route path="practices/:practiceId" element={<PracticeDetails />}></Route>
          <Route path="onboard" element={<OnBoardPracticeList />}></Route>
          <Route path="onboard/:practiceId" element={<OnboardPracticeDetails />}></Route>
          <Route path="onboard/newPractice" element={<OnboardNewPractice />}></Route>
          <Route path="phn" element={<PHNSettings />}></Route>
          <Route path="telemetry" element={<PbiReport />}></Route>
          <Route path="*" element={<p>There's nothing here!</p>} />
        </Routes>
      </main>
    </div>
  )
}

const Home = ({ userProfile }) => {
  return <h2>Welcome {userProfile.displayName}</h2>
}

export default Dashboard

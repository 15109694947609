import { useEffect, useState } from "react"
import { useMsal } from '@azure/msal-react'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { Spinner, Toast, ToastContainer, Button, Col, Container, Row, Card } from "react-bootstrap"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import BForm from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import './OnboardPracticeDetails.css'
import Progress from "./Progress"
import OnboardPracticeGroup from "./OnboardPracticeGroup"
import { ConfirmDialog } from "components/ConfirmDialog"

let intervalId

const OnboardPracticeDetails = () => {
    const [practice, setPractice] = useState({})
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const [refData, setRefData] = useState({})
    const [submit, setSubmit] = useState({})
    const [showConfirmCloseWorkflow, setShowConfirmCloseWorkflow] = useState(false)
    const [showConfirmCancellation, setShowConfirmCancellation] = useState(false)
    const [showRestartOnboardingConfirm, setShowRestartOnboardingConfirm] = useState(false)
    const { instance, accounts } = useMsal()
    const params = useParams()
    const STATUS_TO_BE_ONBOARDED = 10;
    const STATUS_CLIENT_ID_EMAILED = 30;
    const STATUS_INSTALLATION_STARTED = 40;
    const STATUS_CLOSED = 50;
    const STATUS_CANCELLED = 100;

    const mapToPracticeDetails = (data) => {
        return {
            id: data.id,
            practiceName: _.get(data, 'name'),
            abbreviation: _.get(data, 'abbreviation'),
            practiceSoftwareId: _.get(data, 'software.id'),
            postCodeId: _.get(data, 'postcode.code'),
            practiceGroupId: _.get(data, 'group.id'),
            phnName: _.get(data, 'phn.name'),
            pipQIStatusID: _.get(data, 'pipqiStatus.id'),
            managerEmail: _.get(data, 'managerEmail'),
            itProviderEmail: _.get(data, 'itProviderEmail'),
            dataShareAgreementConfirmed: _.get(data, 'dataShareAgreementConfirmed'),
            onboardingStatusID: _.get(data, 'onboardingStatusID'),
            onboardingStatus: _.get(data, 'onboardingStatus.description'),
            appClientId: _.get(data, 'appClientId'),
            onboardingWorkflowHasError: _.get(data, 'onboardingWorkflowHasError'),
            onboardingWorkflowCreateTime: Date.parse(_.get(data, 'onboardingWorkflowCreateTime'))
        }
    }

    async function fetchData() {
        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }

        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}`, httpConfig)
        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/refdata`, httpConfig)
        setRefData(refResult.data)
        const practiceDetails = mapToPracticeDetails(result.data)
        setPractice(practiceDetails)

        if (practiceDetails.onboardingStatusID > STATUS_TO_BE_ONBOARDED && practiceDetails.onboardingStatusID < STATUS_CLOSED) setupAutoStatusUpdate()
    }

    useEffect(() => {
        fetchData()

        return () => {
            // Anything in here is fired on component unmount.
            clearInterval(intervalId)
        }
    }, [submit])

    const validationSchema = Yup.object().shape({
        practiceName: Yup.string()
            .required('Name is required')
            .max(255, 'Name must not exceed 255 characters'),
        abbreviation: Yup.string()
            .required('Abbreviation is required')
            .max(20, 'Abbreviation must not exceed 20 characters'),
        postCodeId: Yup.number()
            .required('Post Code is required')
            .max(99999, 'Invalid post code'),
        practiceSoftwareId: Yup.string()
            .nullable()
            .required('Software is required'),
        pipQIStatusID: Yup.string()
            .nullable()
            .required('PIP QI Status is required'),
        managerEmail: Yup.string()
            .email('Manager Email is not valid'),
        itProviderEmail: Yup.string()
            .email('IT Provider Email is not valid'),
    })
    const dsaValidationSchema = Yup.object().shape({
        dataShareAgreementConfirmed: Yup.boolean()
            .test('isDSAChecked', 'Please accept data sharing agreement', function (value) {
                return value
            }),
        itProviderEmail: Yup.string()
            .nullable()
            .required('IT Provider Email is required')
            .email('IT Provider Email is not valid'),
        managerEmail: Yup.string()
            .nullable()
            .required('Manager Email is required')
            .email('Manager Email is not valid')
    })

    const setupAutoStatusUpdate = () => {
        if (intervalId) clearInterval(intervalId)
        console.log('setupAutoStatusUpdate')
        intervalId = setInterval(async () => {
            const authHeader = await getAuthHeader(instance, accounts)
            const httpConfig = {
                headers: authHeader
            }

            const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}`, httpConfig)
            const practiceDetails = mapToPracticeDetails(result.data)

            setPractice(practiceDetails)
        }, 5000)
    }

    const fetchRef = async (newPracticeGroupId) => {
        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/refdata`, httpConfig)

        setRefData(refResult.data)
        setPractice({
            ...practice,
            practiceGroupId: newPracticeGroupId
        })
    }

    const closeWorkflow = async (setIsSubmitting) => {
        setIsSubmitting(true)

        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}/close`, null, httpConfig)
        setShowToastMsg({ visible: true, msg: 'Onboarding closed' })

        setIsSubmitting(false)
        setShowConfirmCloseWorkflow(false)
        //refresh
        setSubmit(true)
    }

    const cancelWorkflow = async (setIsSubmitting) => {
        setIsSubmitting(true)

        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}/cancel`, null, httpConfig)

        setIsSubmitting(false)
        setShowConfirmCancellation(false)
        setShowToastMsg({ visible: true, msg: 'Onboarding cancelled, the cancellation email will be sent shortly' })
        //refresh
        setSubmit(true)
    }

    const restartWorkflow = async (setIsSubmitting) => {
        setIsSubmitting(true)

        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}/restart`, null, httpConfig)

        setIsSubmitting(false)
        setShowToastMsg({ visible: true, msg: 'Onboarding restarted' })
        setShowRestartOnboardingConfirm(false)
        //refresh
        setSubmit(true)
    }

    const regenLimit = 1000 * 60 * 60 * 24; // 1 day
    const onboardingInProgress = () => practice.dataShareAgreementConfirmed && practice.onboardingStatusID < STATUS_CLOSED
    const workflowSuspended = () => practice.onboardingWorkflowHasError == true && practice.onboardingStatusID < STATUS_INSTALLATION_STARTED
    const regenerateLimitReached = () => practice.onboardingWorkflowCreateTime < (Date.now() - regenLimit);
    const canRegenerateSecret = () => regenerateLimitReached() && practice.onboardingStatusID === STATUS_CLIENT_ID_EMAILED

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {!practice.id && <Spinner animation="border" variant="secondary" />}

            {practice.id &&
                <>
                    <Formik
                        enableReinitialize={true}
                        initialValues={practice}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                            const authHeader = await getAuthHeader(instance, accounts)
                            await axios.put(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}/settings`, values, { headers: authHeader })
                            setShowToastMsg({ visible: true, msg: 'Saved' })
                            //refresh
                            setSubmit(true)
                        }}
                    >
                        {({ handleSubmit, errors, touched, isSubmitting, isValidating, dirty, resetForm }) => {
                            return (
                                <Container>
                                    <Progress status={practice.onboardingStatusID} />
                                    <Form>
                                        <Row className="mb-3">
                                            <Col xs={9}>
                                                <BForm.Group>
                                                    <BForm.Label>Practice Name</BForm.Label>
                                                    <Field type="text" name="practiceName" className={'form-control'} />
                                                    <ErrorMessage name="practiceName" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <BForm.Group>
                                                    <BForm.Label>Abbreviation</BForm.Label>
                                                    <Field type="text" name="abbreviation" className={'form-control'} />
                                                    <ErrorMessage name="abbreviation" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <BForm.Group controlId="practiceSoftwareId">
                                                    <BForm.Label>Practice Software</BForm.Label>
                                                    <Field name="practiceSoftwareId" as="select" disabled={practice.onboardingStatusID >= 40} className={'form-control form-select'}>
                                                        <option value=""></option>
                                                        {
                                                            refData.softwares && refData.softwares.map(s => (
                                                                <option key={s.id} value={s.id}>{s.name}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="practiceSoftwareId" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group controlId="pipQIStatusID">
                                                    <BForm.Label>PIP QI Status</BForm.Label>
                                                    <Field name="pipQIStatusID" as="select" className={'form-control form-select'}>
                                                        <option value=""></option>
                                                        {
                                                            refData.pipqiStatus && refData.pipqiStatus.map(s => (
                                                                <option key={s.id} value={s.id}>{s.description}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="pipQIStatusID" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group controlId="postCodeId">
                                                    <BForm.Label>Post Code</BForm.Label>
                                                    <Field name="postCodeId" type="number" className={'form-control'}>
                                                    </Field>
                                                    <ErrorMessage name="postCodeId" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>

                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={4}>
                                                <BForm.Group>
                                                    <BForm.Label>Practice Group</BForm.Label>
                                                    <div className="d-flex flex-row align-items-start" >
                                                        <Field name="practiceGroupId" as="select" className={'form-control form-select'}>
                                                            <option value="0"></option>
                                                            {
                                                                refData.groups && refData.groups.map(s => (
                                                                    <option key={s.id} value={s.id}>{s.name}</option>
                                                                ))
                                                            }
                                                        </Field>
                                                    </div>
                                                </BForm.Group>
                                            </Col>
                                            <Col xs={8}>
                                                <BForm.Group>
                                                    <BForm.Label>&nbsp;</BForm.Label>
                                                    <OnboardPracticeGroup onClick={fetchRef} />
                                                </BForm.Group>
                                            </Col>
                                        </Row>

                                        {
                                        // Allow to update once onboarding workflow started
                                        practice.dataShareAgreementConfirmed === true &&
                                        <Row className="mb-3">
                                            <Col >
                                                <BForm.Group>
                                                    <BForm.Label>Manager Email</BForm.Label>
                                                    <Field type="text" name="managerEmail" className="form-control" />
                                                    <ErrorMessage name="managerEmail" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group>
                                                    <BForm.Label>IT Provider Email</BForm.Label>
                                                    <Field type="text" name="itProviderEmail" className="form-control" />
                                                    <ErrorMessage name="itProviderEmail" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                        </Row>
                                        }

                                        {practice.onboardingStatusID != STATUS_CANCELLED &&
                                            <Row>
                                                <Col>
                                                    <Button type="submit" variant="outline-primary" disabled={isSubmitting}>
                                                        Save {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                                    </Button>
                                                </Col>
                                            </Row>}
                                        <Row className="mt-3">
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={practice}
                                                validationSchema={dsaValidationSchema}
                                                onSubmit={async (values, actions) => {
                                                    const authHeader = await getAuthHeader(instance, accounts)
                                                    await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}/dsaconfirmed`, values, { headers: authHeader })
                                                    setShowToastMsg({ visible: true, msg: 'DSA confirmed, the welcome email with installation details will be sent to IT provider shortly' })
                                                    setupAutoStatusUpdate()
                                                }}
                                            >
                                                {({ handleSubmit, isSubmitting }) => {
                                                    return (
                                                        <Container>
                                                            <Card className="mt-4">
                                                                <Card.Body>
                                                                    <Card.Title>Onboarding</Card.Title>
                                                                    <Card.Text>
                                                                        <Form>
                                                                            {
                                                                            // Manager and IT provider emails are required before starting onboarding process 
                                                                            !practice.dataShareAgreementConfirmed &&
                                                                            <Row className="mb-3">
                                                                                <Col >
                                                                                    <BForm.Group>
                                                                                        <BForm.Label>Manager Email</BForm.Label>
                                                                                        <Field type="text" name="managerEmail" className="form-control" />
                                                                                        <ErrorMessage name="managerEmail" component="div" className="invalid-feedback d-block" />
                                                                                    </BForm.Group>
                                                                                </Col>
                                                                                <Col>
                                                                                    <BForm.Group>
                                                                                        <BForm.Label>IT Provider Email</BForm.Label>
                                                                                        <Field type="text" name="itProviderEmail" className="form-control" />
                                                                                        <ErrorMessage name="itProviderEmail" component="div" className="invalid-feedback d-block" />
                                                                                    </BForm.Group>
                                                                                </Col>
                                                                            </Row>
                                                                            }
                                                                            {practice.onboardingStatusID > 10 &&
                                                                                <Row className="mb-3">
                                                                                    <Col>
                                                                                        <BForm.Group>
                                                                                            <BForm.Label>App Client ID</BForm.Label>
                                                                                            <Field type="text" name="appClientId" className="form-control" disabled={true} />
                                                                                        </BForm.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <Row className="mb-3">
                                                                                <Col>
                                                                                    <div className="form-check">
                                                                                        <Field type="checkbox" name="dataShareAgreementConfirmed" className="form-check-input" disabled={practice.dataShareAgreementConfirmed} />
                                                                                        <label className="form-check-label">Data Share Agreement</label>
                                                                                        <ErrorMessage name="dataShareAgreementConfirmed" component="div" className="invalid-feedback d-block" />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                {!practice.dataShareAgreementConfirmed &&
                                                                                    <Col>
                                                                                        <Button className="ms-3" type="button" variant="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                                                                                            Start {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                                                                        </Button>
                                                                                    </Col>
                                                                                }

                                                                            </Row>
                                                                        </Form>

                                                                        {onboardingInProgress() &&
                                                                            <>
                                                                                <div className="mb-3">
                                                                                    {practice.onboardingStatusID == STATUS_INSTALLATION_STARTED &&
                                                                                    <Button type="button" variant="primary" onClick={() => setShowConfirmCloseWorkflow(true)}>
                                                                                        Force Close
                                                                                    </Button>
                                                                                    }
                                                                                    {showConfirmCloseWorkflow &&
                                                                                        <ConfirmDialog
                                                                                            title="Confirm"
                                                                                            body="Do you want to close the onboarding workflow?"
                                                                                            onconfirm={closeWorkflow}
                                                                                            onhide={() => setShowConfirmCloseWorkflow(false)}
                                                                                        />
                                                                                    }

                                                                                    {practice.onboardingStatusID < STATUS_INSTALLATION_STARTED &&
                                                                                        <Button className="ms-3" type="button" variant="warning" onClick={() => setShowConfirmCancellation(true)}>
                                                                                            Cancel Onboarding
                                                                                        </Button>
                                                                                    }

                                                                                    {showConfirmCancellation &&
                                                                                        <ConfirmDialog
                                                                                            title="Confirm"
                                                                                            body="Do you want to cancel the onboarding workflow?"
                                                                                            onconfirm={cancelWorkflow}
                                                                                            onhide={() => setShowConfirmCancellation(false)}
                                                                                        />
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        }
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>

                                                            { (workflowSuspended() || canRegenerateSecret()) &&
                                                            <Card className="mt-4">
                                                                <Card.Body>
                                                                    <Card.Title>Status</Card.Title>
                                                                    <Card.Text>
                                                                        <p className="lead mt-3">
                                                                            <mark>
                                                                            { workflowSuspended()
                                                                                ? 'Something went wrong, the practice onboarding cannot proceed further, please click restart'
                                                                                : 'To regenerate the Client Id and Secret, please click restart'
                                                                            }
                                                                            </mark>
                                                                        </p>
                                                                        <Button className="ms-3" type="button" variant="primary" onClick={() => setShowRestartOnboardingConfirm(true)}>
                                                                            Restart
                                                                        </Button>

                                                                        {showRestartOnboardingConfirm &&
                                                                            <ConfirmDialog
                                                                                title="Confirm"
                                                                                body="Do you want to restart the onboarding workflow?"
                                                                                onconfirm={restartWorkflow}
                                                                                onhide={() => setShowRestartOnboardingConfirm(false)}
                                                                            />
                                                                        }
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                            }
                                                        </Container>
                                                    )
                                                }}
                                            </Formik>
                                        </Row>

                                    </Form>
                                </Container>
                            )
                        }}
                    </Formik>

                </>
            }
        </>
    )
}
export default OnboardPracticeDetails

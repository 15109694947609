import { SignInButton } from "components/SignInButton"
import './Landing.css'

const Landing = () => {
  return (
    <div className="d-flex h-100 text-center">
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto">
          <div>
            <h3 className="float-md-start mb-0">Primary Sense</h3>
            <nav className="nav nav-masthead justify-content-center float-md-end">
              <a className="nav-link active" aria-current="page" href="#">Home</a>
              <a className="nav-link" href="#">Features</a>
              <a className="nav-link" href="#">Contact</a>
            </nav>
          </div>
        </header>

        <main className="px-3">
          <h1>Landing page.</h1>
          <p className="lead">Anyone can see this page</p>
          <p className="lead">
            <SignInButton />
          </p>
        </main>

        <footer className="mt-auto text-white-50">
          <p>Copyright</p>
        </footer>
      </div>
    </div>
  )
}
export default Landing
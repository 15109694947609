import { useMsal } from "@azure/msal-react"
import { Button } from "react-bootstrap"

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
	const { instance } = useMsal()

	const handleLogin = () => {
		const loginRequest = {
			scopes: ["User.Read"]
		}

		instance.loginRedirect(loginRequest).catch(e => {
			console.log(e)
		})
	}
	return (
		<Button variant="secondary" onClick={() => handleLogin()}>Sign in</Button>
	)
}
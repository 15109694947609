import { useMsal } from '@azure/msal-react';
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper';
import axios from 'axios';
import { useRef, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const BulkUpload = (props) => {
    const { instance, accounts } = useMsal()
    const [bulkData, setBulkData] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [file, setFile] = useState({
        fileName: '',
        errors: ''
    })
    const inputRef = useRef(null);
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            if (e.target.files[0].name.lastIndexOf('.xlsx') === -1) {
                setFile({ fileName: '', errors: 'Please note: Only excel file formats are allowed.' });
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                var processedData = [];
                json.map((item) => {
                    processedData.push({
                        ...item,
                        PipQIStatusID: item.PipQIStatusID === undefined ? null : item.PipQIStatusID.toString(),
                        Postcode: item.Postcode === undefined ? null : item.Postcode.toString(),
                        PracticeGroupId: item.PracticeGroupId === undefined ? "0" : item.PracticeGroupId.toString(),
                        PracticeSoftwareID: item.PracticeSoftwareID === undefined ? null : item.PracticeSoftwareID.toString()
                    });
                })

                setBulkData(processedData);
            };
            setFile({ fileName: e.target.files[0].name, errors: '' });
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    async function uploadPractices(event) {
        event.preventDefault();
        var errorMessage = '';

        for (var i = 0; i < bulkData.length; i++) {
            if (bulkData[i].PracticeName === undefined) {
                errorMessage = 'Practice Name is required';
                break;
            } else if (bulkData[i].Abbreviation === undefined) {
                errorMessage = 'Practice Abbreviation is required';
                break;
            } else if (bulkData[i].PipQIStatusID === null) {
                errorMessage = 'PipQIStatusID is required';
                break;
            } else if (bulkData[i].PracticeSoftwareID === null) {
                errorMessage = 'Practice SoftwareID is required';
                break;
            } else if (bulkData[i].Postcode === null) {
                errorMessage = 'Postcode is required';
                break;
            } else if (isNaN(parseInt(bulkData[i].PracticeGroupId))) {
                errorMessage = 'PracticeGroupId is Invalid';
                break;
            } else if (bulkData[i].ManagerEmail !== null && bulkData[i].ManagerEmail !== undefined) {
                if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(bulkData[i].ManagerEmail))) {
                    errorMessage = 'Invalid Manager Email';
                    break;
                }
            }
            else if (bulkData[i].ITProviderEmail !== null && bulkData[i].ITProviderEmail !== undefined) {
                if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(bulkData[i].ITProviderEmail))) {
                    errorMessage = 'Invalid IT Provider Email';
                    break;
                }
            }
        }
        if (errorMessage !== '') {
            setFile({ fileName: '', errors: errorMessage });
            setBulkData([]);
            inputRef.current.value = null;
            return;
        }
        if (file.fileName && bulkData) {
            setSubmitting(true);
            const authHeader = await getAuthHeader(instance, accounts)
            const httpConfig = {
                headers: authHeader
            }
            try {
                await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices/bulkUpload`, bulkData, httpConfig)
                setShowToastMsg({ visible: true, msg: 'File uploaded successfully' })
                props.onUpload();
            }
            catch (exception) {
                console.log('error occurred')
            }
            setBulkData([]);
            setFile({ fileName: '', errors: '' });
            inputRef.current.value = null;
            setSubmitting(false);
        }
        else {
            setFile({ fileName: '', errors: 'Please upload file in excel format' });
            return;
        }
    }

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="d-flex flex-row align-items-start w-100">
                <div className="flex-column w-75">
                    <input type="file" ref={inputRef} className="form-control" id="inputGroupFile04" aria-label="Upload" onChange={readUploadFile} />
                    {file.errors && <div className="invalid-feedback d-block">{file.errors}</div>}
                </div>
                <button className="btn btn-outline-secondary w-25" disabled={submitting} type="button" id="inputGroupFileAddon04" onClick={uploadPractices}>
                    Upload {submitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                </button>
            </div>
        </>
    )
}
export default BulkUpload;
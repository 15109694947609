import { useMsal } from '@azure/msal-react'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import BForm from 'react-bootstrap/Form'
import * as Yup from 'yup'
import moment from 'moment'
import { Button, Card, Col, Container, InputGroup, Row, Toast, ToastContainer } from 'react-bootstrap'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { ConfirmDialog } from 'components/ConfirmDialog'
import AuthContext from 'store/auth-context'

const PracticeSettings = ({ practice }) => {
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const [stopped, setStopped] = useState(practice.stopped)
    const [showConfirmPracticeStop, setShowConfirmPracticeStop] = useState(false)
    const [showConfirmPracticeActivate, setShowConfirmPracticeActivate] = useState(false)
    const { instance, accounts } = useMsal()
    const ctx = useContext(AuthContext);
    const params = useParams()

    async function stopPractice() {
        const authHeader = await getAuthHeader(instance, accounts)
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/stop`, null, { headers: authHeader })
        setShowConfirmPracticeStop(false)
        setShowToastMsg({ visible: true, msg: 'Practice stopped' })
        setStopped(true)
    }

    async function activatePractice() {
        const authHeader = await getAuthHeader(instance, accounts)
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/activate`, null, { headers: authHeader })
        setShowConfirmPracticeActivate(false)
        setShowToastMsg({ visible: true, msg: 'Practice enabled' })
        setStopped(false)
    }

    const validationSchema = Yup.object().shape({
        alerts: Yup.boolean(),
        alertsPollingCount: Yup.number()
            .required('Alerts Polling Count is required')
            .min(1),
        alertsPollingPeriod: Yup.number()
            .required('Alerts Polling Period is required')
            .min(2),
        prompts: Yup.boolean(),
        promptsPollingCount: Yup.number()
            .required('Prompts Polling Count is required')
            .min(1),
        promptsPollingPeriod: Yup.number()
            .required('Prompts Polling Period is required')
            .min(2),
        extractPollingPeriod: Yup.number()
            .required('Extract Polling Period is required')
            .min(1),
        extractRecordCount: Yup.number()
            .required('Extract Record Count is required')
            .min(1),
        firstRunExtractPollingPeriod: Yup.number()
            .required('Quiet Hour Extract Polling Period is required')
            .min(1),
        firstRunExtractRecordCount: Yup.number()
            .required('Quiet Hour Extract Record Count is required')
            .min(1),
        extractQuietStartHour: Yup.number()
            .required('Quiet Start Hour is required')
            .min(0).max(23),
        extractQuietEndHour: Yup.number()
            .required('Quiet End Hour is required')
            .min(0).max(23),
        patientSharing: Yup.boolean(),
        isLinXmart: Yup.boolean()
    });

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Formik
                enableReinitialize={true}
                initialValues={practice.settings}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const authHeader = await getAuthHeader(instance, accounts)
                    await axios.put(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/settings`, values, { headers: authHeader })
                    resetForm({ values })
                    setSubmitting(false)
                    setShowToastMsg({ visible: true, msg: 'Settings saved' })
                }}
            >
                {({ errors, touched, isSubmitting, dirty, resetForm }) => {
                    return (
                        <Container>
                            <Form>
                                <Row className="mb-3 gy-3">
                                    <Col xs={4} className="d-flex align-items-center">
                                        <div className="form-check">
                                            <Field type="checkbox" name="alerts" className="form-check-input" disabled={!ctx.isAdmin} />
                                            <label className="form-check-label">Alerts</label>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Alerts Polling Count</BForm.Label>
                                            <Field type="text" name="alertsPollingCount" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="alertsPollingCount" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Alerts Polling Period</BForm.Label>
                                            <InputGroup>
                                                <Field type="text" name="alertsPollingPeriod" className="form-control" disabled={!ctx.isAdmin} />
                                                <InputGroup.Text>seconds</InputGroup.Text>
                                            </InputGroup>
                                            <ErrorMessage name="alertsPollingPeriod" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <div className="form-check">
                                            <Field type="checkbox" name="prompts" className="form-check-input" disabled={!ctx.isAdmin} />
                                            <label className="form-check-label">Prompts</label>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Prompts Polling Count</BForm.Label>
                                            <Field type="text" name="promptsPollingCount" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="promptsPollingCount" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Prompts Polling Period</BForm.Label>
                                            <InputGroup>
                                                <Field type="text" name="promptsPollingPeriod" className="form-control" disabled={!ctx.isAdmin} />
                                                <InputGroup.Text>seconds</InputGroup.Text>
                                            </InputGroup>
                                            <ErrorMessage name="promptsPollingPeriod" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Extract Polling Period</BForm.Label>
                                            <InputGroup>
                                                <Field type="text" name="extractPollingPeriod" className="form-control" disabled={!ctx.isAdmin} />
                                                <InputGroup.Text>seconds</InputGroup.Text>
                                            </InputGroup>
                                            <ErrorMessage name="extractPollingPeriod" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <BForm.Group>
                                            <BForm.Label>Extract Record Count</BForm.Label>
                                            <Field type="text" name="extractRecordCount" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="extractRecordCount" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Command Timeout</BForm.Label>
                                            <Field type="text" name="commandTimeout" disabled={true} className="form-control" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Quiet Hour - Extract Polling Period</BForm.Label>
                                            <InputGroup>
                                                <Field type="text" name="firstRunExtractPollingPeriod" className="form-control" disabled={!ctx.isAdmin} />
                                                <InputGroup.Text>seconds</InputGroup.Text>
                                            </InputGroup>
                                            <ErrorMessage name="firstRunExtractPollingPeriod" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <BForm.Group>
                                            <BForm.Label>Quiet Hour - Extract Record Count</BForm.Label>
                                            <Field type="text" name="firstRunExtractRecordCount" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="firstRunExtractRecordCount" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Server</BForm.Label>
                                            <Field type="text" name="server" disabled={true} className="form-control" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Api Port</BForm.Label>
                                            <Field type="text" name="apiPort" disabled={true} className="form-control" />
                                        </BForm.Group>
                                    </Col>

                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Quiet Start Hour</BForm.Label>
                                            <Field type="text" name="extractQuietStartHour" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="extractQuietStartHour" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Quiet End Hour</BForm.Label>
                                            <Field type="text" name="extractQuietEndHour" className="form-control" disabled={!ctx.isAdmin} />
                                            <ErrorMessage name="extractQuietEndHour" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <div className="form-check">
                                            <Field type="checkbox" name="patientSharing" className="form-check-input" disabled={!ctx.isAdmin} />
                                            <label className="form-check-label">Patient Sharing</label>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <Field type="checkbox" name="isLinXmart" className="form-check-input" disabled={!ctx.isAdmin} />
                                                LinXmart Participating
                                            </label>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <Field type="checkbox" name="isBeta" className="form-check-input" disabled={!ctx.isAdmin} />
                                                Beta Channel Opt-In
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                {ctx.isAdmin &&
                                    <Row className="mt-5">
                                        <Col>
                                            <Button type="submit" variant="outline-primary" disabled={isSubmitting || !dirty}>
                                                Save {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                            </Button>

                                            <Button className="ms-3" variant="outline-primary" disabled={!dirty} onClick={() => resetForm()}>
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        </Container>
                    )
                }
                }
            </Formik>
            {ctx.isAdmin &&
                <Container>
                    <Row className="mt-4">
                        <Col>
                            <Card className="mt-4">
                                <Card.Body>
                                    <Card.Title>
                                        {!stopped &&
                                            <Button type="button" variant="warning" onClick={() => setShowConfirmPracticeStop(true)}>
                                                Disable Practice
                                            </Button>}
                                        {showConfirmPracticeStop &&
                                            <ConfirmDialog
                                                title="Confirm"
                                                body="Do you want to disable the practice?"
                                                onconfirm={stopPractice}
                                                onhide={() => setShowConfirmPracticeStop(false)}
                                            />
                                        }
                                        {stopped && 
                                            <>
                                                <p class="text-danger">Practice has been stopped {practice.stoppedDate && 'since ' +  moment(practice.stoppedDate).format('DD/MM/yyyy')}</p>
                                                <Button type="button" onClick={() => setShowConfirmPracticeActivate(true)}>
                                                    Enable Practice
                                                </Button>
                                            </>
                                        }
                                        {showConfirmPracticeActivate &&
                                            <ConfirmDialog
                                                title="Confirm"
                                                body="Do you want to enable the practice?"
                                                onconfirm={activatePractice}
                                                onhide={() => setShowConfirmPracticeActivate(false)}
                                            />
                                        }
                                    </Card.Title>
                                    {!stopped &&
                                        <Card.Text>
                                            This will disable both data extractor and desktop application, so that data flow between the practice and Primary Sense is disabled temporarily.
                                        </Card.Text>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default PracticeSettings

import React, { useState, useEffect } from 'react';
import { getAuthHeader, hasAdminPermission, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import { useMsal } from '@azure/msal-react';
const AuthContext = React.createContext({
  isAdmin: false
});

export const AuthContextProvider = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { instance, accounts } = useMsal()
 

  async function GetAdminPermission(){
    setIsAdmin(await hasAdminPermission(instance, accounts))
  }
  useEffect(() => {
    GetAdminPermission()
  }, []);


  return (
    <AuthContext.Provider
      value={{
        isAdmin: isAdmin
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
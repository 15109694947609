import { HashRouter, Route, Routes, Navigate } from "react-router-dom"
import { useIsAuthenticated } from "@azure/msal-react"
import Dashboard from "./pages/protected/Dashboard.jsx"
import Landing from "pages/public/Landing.jsx"
import { Toast, ToastContainer } from "react-bootstrap"
import { useState } from "react"
import axios from "axios"
import { AuthContextProvider } from "store/auth-context.js"

const App = () => {
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })

    // Global error handling for axios
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let errorMessages = [];

        if (!error || !error.response) {
            errorMessages.push("Unknown error");
        }
        else if (error.response.data
            && error.response.data.title
            && error.response.data.errors
        ) {
            let structuredError = error.response.data;
            errorMessages.push(structuredError.title);

            let fields = Object.keys(structuredError.errors);
            // the keys are a path to the form fields, meant to highlight the fields with errors, etc.
            fields.forEach(function (key) {
                let fieldErrors = structuredError.errors[key];
                errorMessages.push(...fieldErrors);
            });
        }
        else if (error.response.data && typeof error.response.data === 'object') {
            let keys = Object.keys(error.response.data); // get all own property names of the object

            keys.forEach(function (key) {
                let value = error.response.data[key]; // get property value

                // if the property value is an object...
                if (value && typeof value === 'object') {
                    errorMessages.push("   " + value[0]);
                }
            });
        }
        else if (error.response.data && typeof error.response.data === 'string') {
            errorMessages.push(error.response.data);
        }
        else if (error.response.status) {
            errorMessages.push(error.response.status + " " + error.response.statusText);
        }
        else { console.error(error); }

        setShowToastMsg({ visible: true, msg: errorMessages.join("\n").trim() })
        return Promise.reject(error);
    })

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route index element={<LandingNav />} />
                    <Route
                        path="dashboard/*"
                        element={
                            <ProtectedRoute isAuthenticated={useIsAuthenticated()}>
                                <AuthContextProvider>
                                    <Dashboard />
                                </AuthContextProvider>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<p>There's nothing here: 404!</p>} />
                </Routes>
            </HashRouter>

            <ToastContainer className="p-3" position="bottom-center">
                <Toast
                    className="d-inline-block m-1"
                    bg="danger"
                    onClose={() => setShowToastMsg({ visible: false, msg: '' })}
                    show={showToastMsg.visible}
                >
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className="Danger text-white" style={{ whiteSpace: 'pre-line' }}>
                        {showToastMsg.msg}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

const ProtectedRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/" replace />
}

const LandingNav = () => {
    return useIsAuthenticated() ? <Navigate to="/dashboard" replace /> : <Landing />
}

export default App

import _ from 'lodash'

const {
	REACT_APP_PS_PORTAL_API_URI,
	REACT_APP_MSAL_CLIENT_ID
} = process.env

const MS_GRAPH_API_BASE_URI = "https://graph.microsoft.com/v1.0"

async function getAccessToken(instance, accounts) {
  // Silently acquires an access token which is then attached to a request for Portal API data
  try {
    const response = await instance.acquireTokenSilent({
      scopes: [`api://${REACT_APP_MSAL_CLIENT_ID}/access_as_user`], 
      account: accounts[0]
    })
    return response.accessToken
  }
  catch (error) {
    instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		})
  }
}

async function hasAdminPermission(instance, accounts) {
  const response = await instance.acquireTokenSilent({
    scopes: [`api://${REACT_APP_MSAL_CLIENT_ID}/access_as_user`], 
    account: accounts[0]
  })
  const roles = _.get(response, 'idTokenClaims.roles')

  return _.includes(roles, 'PhnAdminUserRole')
}

async function getAuthHeader(instance, accounts) {
  const accessToken = await getAccessToken(instance, accounts)
  return {
    'Authorization': `Bearer ${accessToken}`
  }
}

async function getGraphApiAuthHeader(instance, accounts, scopes) {
  try {
    const getAccessTokenRes = await instance.acquireTokenSilent({
      scopes,
      account: accounts[0]
    })
  
    return {
      headers: {
        'Authorization': `Bearer ${getAccessTokenRes.accessToken}`,
        'ConsistencyLevel': 'eventual'
      }
    }
  }
  catch (error) {
    instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		})
  }
}

export { 
  getAuthHeader, 
  getGraphApiAuthHeader, 
  hasAdminPermission,
  REACT_APP_MSAL_CLIENT_ID, 
  REACT_APP_PS_PORTAL_API_URI,
  MS_GRAPH_API_BASE_URI
}
import { useEffect, useState, FormEvent } from "react";
import { Spinner, Toast, ToastContainer, Button, Col, Container, Row, } from "react-bootstrap"
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import BForm from 'react-bootstrap/Form'
import * as Yup from 'yup'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from "authHelper";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import _ from 'lodash'

const PHNSettings = () => {
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const { instance, accounts } = useMsal()
    let [phn, setPHN] = useState({})
    const [refData, setRefData] = useState([])
    
    async function fetchData() {
        setIsRefreshing(true)

        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }

        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/phns/1`, httpConfig)
        const data = result.data
        const phnDetails = {
            id: data.id,
            name: _.get(data, 'name'),
            phnEmail: _.get(data, 'phnEmail'),
            webUrl: _.get(data, 'webUrl'),
            isLinXmart: _.get(data, 'isLinXmart'),
            linXmartKey: _.get(data, 'linXmartKey'),
            linXmartUpdated: new Date(_.get(data, 'linXmartUpdated')),
            linXmartDefinitionID: _.get(data, 'linXmartDefinitionID')
        }
        setPHN(phnDetails)

        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/phns/refdata`, httpConfig)
        setRefData(refResult.data)

        setIsRefreshing(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const validationSchema = Yup.object().shape({
        phnEmail: Yup.string()
            .nullable()
            .required('PHN Email is required')
            .email('PHN Email is not valid'),
        webUrl: Yup.string()
            .nullable()
            .required('PHN URL is required')
            .url('PHN URL is not valid'),
        isLinXmart: Yup.boolean(),
        linXmartKey: Yup.string()
            .max(100, 'Key must be at most 100 characters')
            .when("isLinXmart", {
                is: true,
                then: Yup.string().required("HMAC Key is required")
            }),
        linXmartDefinitionID: Yup.number()
            .typeError('Project Definiton is required')
            .nullable()
            .when("isLinXmart", {
                is: true,
                then: Yup.number().required("Project Definiton must be selected")
            })
    })

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>
            {!phn.id && <Spinner animation="border" variant="secondary" />}
            {phn.id && <Formik
                enableReinitialize={true}
                initialValues={phn}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setIsRefreshing(true)
                    const authHeader = await getAuthHeader(instance, accounts)
                    let send = Object.assign({}, values);
                    //console.log(phn, values, send);
                    if (values.linXmartKey === phn.linXmartKey) {
                        send.linXmartKey = undefined; // Don't overwrite unmodified key
                        //console.log("eq", phn.linXmartKey, values.linXmartKey);
                    }
                    await axios.put(`${REACT_APP_PS_PORTAL_API_URI}/phns/settings`, send, { headers: authHeader })
                    setSubmitting(false)
                    resetForm({ values })
                    setPHN(values);
                    setShowToastMsg({ visible: true, msg: 'PHN Settings Saved successfully' })
                    setIsRefreshing(false)
                }}
            >
                {({ handleSubmit, errors, touched, isSubmitting, isValidating, dirty, resetForm, values }) => {
                    return (
                        <Container>
                            <Form>
                                <Row className="mb-3">
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>PHN Name</BForm.Label>
                                            <Field type="text" name="name" disabled={true} className="form-control" />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>PHN Email</BForm.Label>
                                            <Field type="text" name="phnEmail" className="form-control" />
                                            <ErrorMessage name="phnEmail" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>PHN URL</BForm.Label>
                                            <Field type="text" name="webUrl" className="form-control" />
                                            <ErrorMessage name="webUrl" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col> {/*className="mt-auto"*/}
                                        <BForm.Group className="form-check">
                                            <p>&nbsp;</p>
                                            <BForm.Label>
                                                <Field type="checkbox" name="isLinXmart" className="form-check-input" />
                                                LinXmart Enabled
                                            </BForm.Label>
                                            <ErrorMessage name="isLinXmart" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col>
                                        <BForm.Group>
                                            {values.isLinXmart &&
                                                <>
                                                    <p>LinXmart Key Updated</p>
                                                    <p>
                                                        {values.linXmartUpdated.toLocaleString('en-AU')}
                                                    </p>
                                                </>
                                            }
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            {values.isLinXmart &&
                                                <>
                                                    <BForm.Label>LinXmart Key</BForm.Label>
                                                    <Field type="text" name="linXmartKey" className="form-control" />
                                                    <ErrorMessage name="linXmartKey" component="div" className="invalid-feedback d-block" />
                                                </>
                                            }
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            {values.isLinXmart &&
                                                <>
                                                    <BForm.Label>LinXmart Project Definition</BForm.Label>
                                                    <Field name="linXmartDefinitionID" as="select" className="form-control form-select">
                                                        <option value=""></option>
                                                        {
                                                            refData && refData.map(s => (
                                                                <option key={s.id} value={s.id}>{s.name}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="linXmartDefinitionID" component="div" className="invalid-feedback d-block" />
                                                </>
                                            }
                                        </BForm.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Button variant="outline-primary" disabled={isSubmitting || !dirty} type="submit">
                                            Save {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    )
                }
                }
            </Formik>}
        </>
    )
}

export default PHNSettings;

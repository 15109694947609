import { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import { Button} from "react-bootstrap"


export const ConfirmDialog = ({title, body, onconfirm, onhide}) => {
  // const [showConfirm, setShowConfirm] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  return (
    <Modal show={true} animation={false} centered onHide={() => onhide()}>
      <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={() => onhide()}>
          Cancel
      </Button>
      <Button variant="primary" onClick={() => onconfirm(setIsSubmitting)} disabled={isSubmitting}>
          OK {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
      </Button>
      </Modal.Footer>
    </Modal>
  )
}
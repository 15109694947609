import { useMsal } from '@azure/msal-react'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BForm from 'react-bootstrap/Form'
import { Button, Col, Container, OverlayTrigger, Row, Toast, ToastContainer, Tooltip } from 'react-bootstrap'
import * as Yup from 'yup'
import _ from 'lodash'
import OnboardPracticeGroup from "./OnboardPracticeGroup";

const OnboardNewPractice = () => {
    const navigate = useNavigate()

    const [practice, setPractice] = useState({
        practiceName: '',
        abbreviation: '',
        practiceSoftwareID: '',
        practiceGroupId: '0',
        pipQIStatusID: '',
        postCode:''
    })
    const [refData, setRefData] = useState({})
    const [show, setShow] = useState(false)

    const { instance, accounts } = useMsal()

    async function fetchData() {
        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/refdata`, httpConfig)
        setRefData(refResult.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchRef= async (newPracticeGroupId)=>{
        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }
        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/refdata`, httpConfig)

        setRefData(refResult.data)
        setPractice({
            ...practice,
            practiceGroupId: newPracticeGroupId
        })
    }

    const validationSchema = Yup.object().shape({
        practiceName: Yup.string()
            .required('Name is required')
            .max(255, 'Name must not exceed 255 characters'),
        abbreviation: Yup.string()
            .required('Abbreviation is required')
            .max(20, 'Abbreviation must not exceed 20 characters'),
        postCode: Yup.string()
            .required('Post Code is required')
            .matches(/^\d+$/, 'Invalid post code'),
        practiceSoftwareID: Yup.string()
            .nullable()
            .required('Software is required'),
        pipQIStatusID: Yup.string()
            .nullable()
            .required('PIP QI Status is required'),
        managerEmail: Yup.string()
            .email('Manager Email is not valid'),
        itProviderEmail: Yup.string()
            .email('IT Provider Email is not valid')
    })

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>Create new practice success</Toast.Body>
                </Toast>
            </ToastContainer>
            <Formik
                enableReinitialize={true}
                initialValues={practice}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    const authHeader = await getAuthHeader(instance, accounts)

                    const newPracticeIdResult = await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices`, values, { headers: authHeader })
                    setSubmitting(false)
                    setShow(true)

                    navigate(`/dashboard/onboard/${newPracticeIdResult.data}`)
                }}
            >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                    const renderTooltip = (props) => (
                        <Tooltip {...props}>
                            Please use this field to enter the PHN's Internal reference ID for the Practice, which could be helpful to link Practice records external to Primary Sense
                        </Tooltip>
                    )

                    return (
                        <Container>
                            <Form>
                                <Row className="mb-3">
                                    <Col xs={8}>
                                        <BForm.Group>
                                            <BForm.Label>Practice Name</BForm.Label>
                                            <Field type="text" name="practiceName" className={'form-control'} />
                                            <ErrorMessage name="practiceName" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                                    <BForm.Label>Abbreviation</BForm.Label>
                                            <Field type="text" name="abbreviation" className={'form-control'} />
                                            {/* <BForm.Text className="text-muted">
                                                Please use this field to enter the PHN's Internal reference ID for the Practice, which could be helpful to link Practice records external to Primary Sense
                                            </BForm.Text> */}
                                            <ErrorMessage name="abbreviation" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <BForm.Group controlId="formSoftware">
                                            <BForm.Label>Software</BForm.Label>
                                            <Field name="practiceSoftwareID" as="select" className={'form-control form-select'}>
                                                <option value=""></option>
                                                {
                                                    refData.softwares && refData.softwares.map(s => (
                                                        <option key={s.id} value={s.id}>{s.name}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name="practiceSoftwareID" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>

                                    <Col>
                                        <BForm.Group controlId="pipQIStatusID">
                                            <BForm.Label>PIP QI Status</BForm.Label>
                                            <Field name="pipQIStatusID" as="select" className={'form-control form-select'}>
                                                <option value=""></option>
                                                {
                                                    refData.pipqiStatus && refData.pipqiStatus.map(s => (
                                                        <option key={s.id} value={s.id}>{s.description}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name="pipQIStatusID" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col>
                                        <BForm.Group controlId="formPostCode">
                                            <BForm.Label>Post Code</BForm.Label>
                                            <Field name="postCode" type="text" className={'form-control'}>
                                            </Field>
                                            <ErrorMessage name="postCode" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Group</BForm.Label>
                                            <Field name="practiceGroupId" as="select" className={'form-control form-select'}>
                                                <option value="{0}"></option>
                                                {
                                                    refData.groups && refData.groups.map(s => (
                                                        <option key={s.id} value={s.id}>{s.name}</option>
                                                    ))
                                                }
                                            </Field>
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={8}>
                                            <BForm.Group>
                                                <BForm.Label>&nbsp;</BForm.Label>
                                                <OnboardPracticeGroup onClick={fetchRef} />
                                            </BForm.Group>
                                        </Col>
                                </Row>

                                <Row className="mb-3">

                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>Manager Email</BForm.Label>
                                            <Field type="text" name="managerEmail" className="form-control" />
                                            <ErrorMessage name="managerEmail" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <BForm.Group>
                                            <BForm.Label>IT Provider Email</BForm.Label>
                                            <Field type="text" name="itProviderEmail" className="form-control" />
                                            <ErrorMessage name="itProviderEmail" component="div" className="invalid-feedback d-block" />
                                        </BForm.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="submit" variant="outline-primary" disabled={isSubmitting}>
                                            Save {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Container>
                    )
                }
                }
            </Formik>
        </>
    )
}
export default OnboardNewPractice;

import { useEffect, useState } from 'react'
import axios from 'axios'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import BulkUpload from './BulkUpload'
import TableContainer from '../table/TableContainer'

const OnBoardPracticeList = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const { instance, accounts } = useMsal()
    const columns = [
        {
            Header: "Practice ID",
            accessor: "id"
        },
        {
            Header: "Practice Name",
            accessor: "name",
        },
        {
            Header: "Abbreviation",
            accessor: "abbreviation"
        },
        {
            Header: "Software",
            accessor: "software.name"
        },
        {
            Header: "App Client ID",
            accessor: "appClientId",
        },
        {
            Header: "Status",
            accessor: "onboardingStatus.description"
        }
    ]
    async function loadPractices() {
        const authHeader = await getAuthHeader(instance, accounts)
        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/onboard`, {
            headers: authHeader
        })

        setItems(result.data)
        setLoading(false)
    }

    // https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
    // React.Strict mode is on
    useEffect(() => {
        loadPractices()
    }, [])


    const navigateUser = async (practiceId) => {
        navigate(practiceId.toString())
    }

    return (
        <>
            {loading && <Spinner animation="border" variant="secondary" />}
            {!loading &&
                <>
                    <Row className="mb-3">
                        <Col>
                            <Button variant="outline-primary" onClick={() => navigate("newPractice")}>Add Practice</Button>
                        </Col>
                    </Row>
                    <TableContainer columns={columns} data={items} onClick={navigateUser} />
                    <Container>
                        <Card className="mt-4">
                            <Card.Body>
                                <Card.Title>Bulk Practice Upload</Card.Title>
                                <Card.Text className='mt-4'>
                                    <Row>
                                        <Col xs={12}>
                                            <BulkUpload onUpload={loadPractices} />
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Container>
                </>
            }
        </>
    )
}
export default OnBoardPracticeList
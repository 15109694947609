import { LogLevel } from "@azure/msal-browser"

const {
	REACT_APP_MSAL_CLIENT_ID, 
	REACT_APP_MSAL_AUTHORITY
} = process.env
/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
	auth: {
		clientId: REACT_APP_MSAL_CLIENT_ID,
		authority: REACT_APP_MSAL_AUTHORITY,
		redirectUri: '/'
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		// iframeHashTimeout: 10000, //https://kksimplifies.com/msal-broswer-browserautherror/
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message)
						return
					case LogLevel.Info:
						console.info(message)
						return
					case LogLevel.Verbose:
						console.debug(message)
						return
					case LogLevel.Warning:
						console.warn(message)
						return
				}
			}
		}
	}
}
// TableContainer.js
import React, { Fragment } from "react"
import { useTable, useSortBy, useFilters, usePagination } from "react-table"
import { useExportData } from "react-table-plugins"
import { DefaultColumnFilter, Filter } from "./Filters"
import { Input } from "reactstrap"
import {Table, Button, Row,Col } from 'react-bootstrap'
import Papa from "papaparse"

const TableContainer = (props) => {
const columns = props.columns;
  
const getExportFileBlob = ({ columns, data, fileType, fileName }) => {  
  const headerNames = columns.map(col => col.exportValue);

  if (fileType === "csv") {      
    const csvString = Papa.unparse({ fields: headerNames, data });

    return new Blob([csvString], { type: "text/csv" });
  }

  return false
}

const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData
  } = useTable({
    columns,
    data,
    defaultColumn: { Filter: DefaultColumnFilter },
    initialState: { pageIndex: 0, pageSize: 10 },
    getExportFileBlob
  },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
    // Feel free to use console.log()  This will help you better understand how react table works underhood.
    <Fragment>
      <Table bordered  {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onClick={() => props.onClick(row.original.id)}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row className="d-flex justify-content-between">
        <Col md={2}>
          <Button variant="outline-primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}>
            {"<<"}
          </Button>
          <Button
            variant="outline-primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <Input type="select" value={pageSize} onChange={onChangeInSelect}>        
            {[10, 20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={2}>
          <Button variant="outline-primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button 
            variant="outline-primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
        <Col md={2}>
          <Button variant="outline-primary" className="ms-2" onClick={() => {exportData("csv", true);}}>
            Export To Csv
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default TableContainer
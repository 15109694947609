import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import { Spinner } from 'react-bootstrap';

const PbiReport = () => {
  const { instance, accounts } = useMsal()
  const [defaultReportConfig, setDefaultReportConfig] = useState(null)
  const [loading, setLoading] = useState(true)

  async function getReports() {
    const authHeader = await getAuthHeader(instance, accounts)
    const embedConfigResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/telemetry/pbitoken`, {
      headers: authHeader
    })
    const embedConfig = embedConfigResult.data

    setDefaultReportConfig({
      type: 'report',
      id: embedConfig.reportId,
      embedUrl: embedConfig.embedUrl,
      accessToken: embedConfig.embedToken.token,
      tokenType: models.TokenType.Embed
    })

    setLoading(false)
  }

  // https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  // React.Strict mode is on
  useEffect(() => {
    getReports()
  }, [])

  return (
    <>
    { loading && <Spinner animation="border" variant="secondary" /> }
    { !loading && 
    <div className="pbiReportWrapper">
      {defaultReportConfig &&
      <PowerBIEmbed
        embedConfig={defaultReportConfig}
      />
      }
    </div>
    }
    </>
  )
}

export default PbiReport
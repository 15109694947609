import { useState, useContext } from "react";
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from "authHelper";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import AuthContext from "store/auth-context";

const OnboardPracticeGroup = (props) => {
    const { instance, accounts } = useMsal()
    const ctx = useContext(AuthContext);
    const [practiceGroup, setPracticeGroup] = useState({
        groupName: '',
        errors: ''
    })

    async function createNewGroup(event) {
        event.preventDefault();
        if (practiceGroup.groupName) {
            const authHeader = await getAuthHeader(instance, accounts)
            const httpConfig = {
                headers: authHeader
            }
            const practiceGroupValues = {
                groupName: practiceGroup.groupName
            }
            const newGroupId = await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/onboard`, practiceGroupValues, httpConfig)
            setPracticeGroup({ groupName: '', errors: '' });
            if (newGroupId !== undefined) {
                props.onClick(newGroupId.data);
            }
        }
        else {
            setPracticeGroup({ groupName: '', errors: 'Please enter practice group name' });
            return;
        }
    }
    const isValid = (input) => {
        if (input.value.trim().length === 0) {
            setPracticeGroup({ groupName: '', errors: 'Please enter practice group name' });
            return false;
        }
        return true;
    };

    const inputChangeHandler = (event) => {
        if (!isValid(event.target)) return;
        setPracticeGroup({ groupName: event.target.value, errors: '' });
    };


    return (
        <div className="d-flex flex-row align-items-start">
            <div className="flex-column w-75">
                <input type="text" value={practiceGroup.groupName} name="groupName" onChange={inputChangeHandler} className="form-control" placeholder="New Practice Group" />
                {practiceGroup.errors && <div className="invalid-feedback d-block">{practiceGroup.errors}</div>}
            </div>
            <button disabled={!ctx.isAdmin} className=" w-25 btn btn-outline-primary" type="button" onClick={createNewGroup}>
                Add Group
            </button>
        </div>
    )
}
export default OnboardPracticeGroup;